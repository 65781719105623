import React from "react"
// import { Link } from "gatsby"
import styled, { css, keyframes } from "styled-components"
import moment from "moment"
import Layout from "../components/Layout"
import { transcode } from "buffer"

const glow = keyframes`
    from {
        text-shadow: 0 0 5px var(--racecar-orange);
    }
    to {
        text-shadow: 0 0 20px var(--racecar-orange);
    }
`

const ScheduleCard = styled.div`
    & .card {
        border: none;
        width: 100%;
        border-radius: 20px;
        position: relative;
        background: rgb(0, 0, 0, 0.25) !important;
        border: ${props =>
            props.active && "1px solid var(--racecar-orange) !important"};

        .card-body {
            z-index: 1;
        }

        .card-footer {
            background-color: ${props =>
                props.active ? "var(--racecar-orange)" : "transparent"};
            color: var(--milky-way-white);
            border: none;
            border-radius: 0 0 18px 18px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            z-index: 1;
            &:hover {
                background-color: var(--milky-way-white);
                color: var(--racecar-orange);
            }
        }
    }
`

// const ReturnTime = () => {
//     var format = "hh:mm:ss"

//     var time = moment("hh:mm:ss")
//     console.log(time)
//     var beforeTime = moment("23:00:00", format)
//     var afterTime = moment("00:00:00", format)
//     console.log(beforeTime)
//     console.log(afterTime)
//     if (time.isBetween(beforeTime, afterTime)) {
//         return true
//     } else {
//         return false
//     }
// }
export default () => (
    <Layout headerTitle="Schedule" headerSubtitle="">
        <div className="row">
            <div className="col-xl-6">
                <h2>ANVIL STAGE</h2>
                <div className="card-group flex-column">
                    <ScheduleCard active>
                        <div className="card bg-dark border-info">
                            <h3 className="card-header header-m-0">
                                1:00 PM //THE ADVENTURE BEGINS__
                            </h3>
                            <div className="card-body">
                                <p className="card-text">
                                    See what’s to come at CitizenCon 2949
                                </p>
                            </div>
                            {/* <div className="card-footer">
                                <a
                                    href="https://twitch.tv/starcitizen"
                                    className="link"
                                >
                                    Watch Now
                                </a>
                            </div> */}
                            <a href="#" className="card-footer btn">
                                {/* <span className="ts-0">Day 1</span> */}
                                <span className="ts-0">
                                    WATCH NOW ON TWITCH >>
                                    {/* WATCH{" "}
                                    {moment([2019, 10, 25])
                                        .from(moment([2019, 10, 24]))
                                        .toUpperCase()}{" "}
                                    ON TWITCH >> */}
                                </span>
                            </a>
                        </div>
                    </ScheduleCard>
                    <ScheduleCard>
                        <div className="card bg-dark mt-4">
                            <h3 className="card-header header-m-0">
                                1:00 PM //THE ADVENTURE BEGINS__
                            </h3>
                            <div className="card-body">
                                <p className="card-text">
                                    This is a wider card with supporting text
                                    below as a natural lead-in to additional
                                    content. This content is a little bit
                                    longer.
                                </p>
                            </div>
                            <a href="#" className="card-footer btn">
                                <span className="ts-0">
                                    WATCH NOW ON TWITCH >>
                                </span>
                            </a>
                        </div>
                    </ScheduleCard>
                    <ScheduleCard>
                        <div className="card bg-dark mt-4">
                            <h3 className="card-header header-m-0">
                                1:00 PM //THE ADVENTURE BEGINS__
                            </h3>
                            <div className="card-body">
                                <p className="card-text">
                                    This is a wider card with supporting text
                                    below as a natural lead-in to additional
                                    content. This content is a little bit
                                    longer.
                                </p>
                            </div>
                            <a href="#" className="card-footer btn">
                                <span className="ts-0">
                                    WATCH NOW ON TWITCH >>
                                </span>
                            </a>
                        </div>
                    </ScheduleCard>
                </div>
            </div>

            <div className="col-xl-6">
                <h2>CROSHAW STAGE</h2>
                <div className="card-group flex-column">
                    <ScheduleCard>
                        <div className="card bg-dark">
                            <h3 className="card-header header-m-0">
                                1:00 PM //THE ADVENTURE BEGINS__
                            </h3>
                            <div className="card-body">
                                <p className="card-text">
                                    This is a wider card with supporting text
                                    below as a natural lead-in to additional
                                    content. This content is a little bit
                                    longer.
                                </p>
                            </div>
                            <a href="#" className="card-footer btn">
                                <span className="ts-0">
                                    WATCH NOW ON TWITCH >>
                                </span>
                            </a>
                        </div>
                    </ScheduleCard>
                    <ScheduleCard>
                        <div className="card bg-dark mt-4">
                            <h3 className="card-header header-m-0">
                                1:00 PM //THE ADVENTURE BEGINS__
                            </h3>
                            <div className="card-body">
                                <p className="card-text">
                                    This is a wider card with supporting text
                                    below as a natural lead-in to additional
                                    content. This content is a little bit
                                    longer.
                                </p>
                            </div>
                            <a href="#" className="card-footer btn">
                                <span className="ts-0">
                                    WATCH NOW ON TWITCH >>
                                </span>
                            </a>
                        </div>
                    </ScheduleCard>
                    <ScheduleCard>
                        <div className="card bg-dark mt-4">
                            <h3 className="card-header header-m-0">
                                1:00 PM //THE ADVENTURE BEGINS__
                            </h3>
                            <div className="card-body">
                                <p className="card-text">
                                    This is a wider card with supporting text
                                    below as a natural lead-in to additional
                                    content. This content is a little bit
                                    longer.
                                </p>
                            </div>
                            <a href="#" className="card-footer btn">
                                <span className="ts-0">
                                    WATCH NOW ON TWITCH >>
                                </span>
                            </a>
                        </div>
                    </ScheduleCard>
                </div>
            </div>
        </div>
    </Layout>
)
